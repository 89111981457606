import {
  ref,
  onMounted,
  getCurrentInstance,
  defineComponent
} from 'vue'
import {
  useRouter,
  useRoute
} from 'vue-router'
import ClaimAndPaymentForm from '@/views/master/claim-and-insurance/claim-and-payment/ClaimAndPaymentForm.vue'
import AddClaimForm from '@/views/master/claim-and-insurance/claim-and-payment/form/AddClaimForm.vue'
import PaymentForm from '@/views/master/claim-and-insurance/claim-and-payment/form/PaymentForm.vue'
import PenaltyDetailTable from '@/views/master/claim-and-insurance/claim-and-payment/components/PenaltyDetail.vue'
import {
  claimUseCase
} from '@/domain/usecase'
import {
  useStore
} from 'vuex'
import moment from 'moment'
import { checkPermission } from '@/utils/helpers/checkPermission'
import PermissionEnum from '@/utils/enum/permissions'
import rupiahFormat from '@/utils/helpers/rupiahFormat'

export default defineComponent({
  name: 'SubmittedPenaltyList',
  components: {
    AddClaimForm,
    PaymentForm,
    PenaltyDetailTable
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $toast,
      $strInd,
      $confirm
    } = app!.appContext.config.globalProperties
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const op = ref()
    const dataEdit = ref(null) as any
    const sliderActive = ref(false)
    const dataSource = ref([]) as any
    const dataHistory = ref([])
    const filterCal = ref()
    const sendDateFilter = ref(false)
    const dateFilter = ref(null)
    const inputSearch = ref('')
    const expandedRows = ref([]) as any
    const selectedRow = ref(null) as any
    const detailSelectedRow = ref([]) as any
    const formType = ref(null) as any
    const isLoading = ref(true)
    const filters = {
      pageNumber: 1,
      pageSize: 10,
      totalRecords: 0,
      search: '',
      filterField: ['agentInChargeName'],
      custom: [['status', '=', 'Submitted'], ['orderBy', '=', 'CreatedAt desc']] as any
    }
    const isPaymentKlaim = ref(false)
    const showDialogHistory = ref(false)

    const addedKlaimAgent = (val: any) => {
      isPaymentKlaim.value = false
      sliderActive.value = true
      dataEdit.value = val
    }

    // const paymentKlaim = (val: any) => {
    //   console.log('xxx')
    //   isPaymentKlaim.value = true
    //   sliderActive.value = true
    //   dataEdit.value = val
    // }

    const showDropDown = (evt: any, val: any) => {
      dataEdit.value = val
      op.value.toggle(evt)
    }

    const hideDialogHistory = () => {
      showDialogHistory.value = false
    }

    const colorAndBgHistory = (IsPayment: any) => {
      let bg
      let color
      if (IsPayment) {
        bg = 'background-green-lighten-5'
        color = 'color-green'
      } else {
        bg = 'background-red-lighten-5'
        color = 'color-red'
      }
      return {
        bg,
        color
      }
    }

    const getHistory = async (idAgent: any) => {
      store.dispatch('showLoading')
      const filter = {
        filter: `AgenId eq ${idAgent} ${dateFilter.value ? `and TanggalKlaim eq ${moment(dateFilter.value).format('YYYY-MM-DD')}` : ''}`,
      }
      const response = await claimUseCase.getAllTransKlaim(filter)
      if (response.error) {
        $toast.add({
          severity: 'error',
          detail: $strInd.toast.errorAllData,
          group: 'bc',
          life: 1500
        })
      } else {
        dataHistory.value = response.result
      }
      store.dispatch('hideLoading')
    }

    const getAllData = async () => {
      const response = await claimUseCase.getAllTransKlaim(filters)
      // console.log('res', response)
      if (response.error) {
        $toast.add({
          severity: 'error',
          detail: $strInd.toast.errorAllData,
          group: 'bc',
          life: 1500
        })
      } else {
        dataSource.value = response.result.Data.map((data: any) => ({
          ...data,
          ListReference: [] as any,
          Date: moment(data.CreatedAt).format('DD MMM YYYY HH:mm:ss')
        }))

        dataSource.value.map(async (data: any) => {
          const arrReference = [] as any
          const referecesResponse = await claimUseCase.getPenaltyReferences(data.Id)
          console.log('ref', [data.Id, referecesResponse.result])
          // eslint-disable-next-line array-callback-return
          referecesResponse.result.map((item: any) => {
            const obj = {
              label: item.Name,
              color: item.Attributes.length > 0 ? item.Attributes[0]?.Value : null
            }
            arrReference.push(obj)
          })
          data.ListReference = arrReference
        })

        isLoading.value = false
        console.log('data', dataSource.value)

        filters.totalRecords = response.result.Count
      }
      store.dispatch('hideLoading')
    }

    const showingHistory = async (val: any) => {
      dataEdit.value = await val
      showDialogHistory.value = true
      getHistory(val.AgenId)
    }

    const closeSidebar = () => {
      sliderActive.value = false
      expandedRows.value = []
    }
    const showFilterDate = (evt: any) => {
      filterCal.value.toggle(evt)
    }

    const saveFilterDate = () => {
      sendDateFilter.value = true
      getHistory(dataEdit.value.AgenId)
      filterCal.value.hide()
    }

    const clearFilter = () => {
      dateFilter.value = null
      sendDateFilter.value = false
      getHistory(dataEdit.value.AgenId)
      filterCal.value.hide()
    }

    const onPage = (event: any) => {
      filters.pageNumber = event.page + 1
      expandedRows.value = []
      dataSource.value = []
      getAllData()
    }

    const onRowExpand = (event: any) => {
      console.log('eventExp', expandedRows.value)
    }

    const onRowCollapse = (event: any) => {
      console.log('eventCol', expandedRows.value)
    }

    const rowExpandAct = async (data: any) => {
      store.dispatch('showLoading')
      console.log('length', expandedRows.value.length)
      console.log((expandedRows.value.length > 0 && data.Id !== expandedRows.value[0].Id) || expandedRows.value.length === 0 ? 'true' : 'false')
      if ((expandedRows.value.length > 0 && data.Id !== expandedRows.value[0].Id) || expandedRows.value.length === 0) {
        expandedRows.value = []
        detailSelectedRow.value = []
        console.log('dataId', data.Id)
        expandedRows.value.push(data)
        // await getPenaltyPaymentsData(data.Id)
      } else if (expandedRows.value.length > 0 && data.Id === expandedRows.value[0].Id) {
        expandedRows.value = []
        detailSelectedRow.value = []
      }
      console.log('exprow', expandedRows.value)
      store.dispatch('hideLoading')
    }

    const rowApproveAct = (data: any) => {
      console.log('data', data)
      const payload = {
        note: 'ok'
      }
      $confirm.require({
        header: 'Proses Approve Klaim',
        message: 'Apakah anda yakin ingin melakukan proses approve klaim, lanjutkan ?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const response = await claimUseCase.approveDataPenalty(data.Id, payload)
          if (response.error) {
            $toast.add({
              severity: 'error', detail: $strInd.toast.errorSubmit, group: 'bc', life: 3000
            })
          } else {
            $toast.add({
              severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
            })
            getAllData()
          }
        }
      })
    }

    const showSidebar = (data: any = null, type: any) => {
      console.log('xxxx', type)
      selectedRow.value = data
      formType.value = type
      sliderActive.value = true
    }

    const onSearch = (val: any) => {
      filters.search = val
      filters.pageNumber = 1
      expandedRows.value = []
      getAllData()
    }

    onMounted(() => {
      store.dispatch('showLoading')
      getAllData()
      console.log('store', store.state)
    })

    return {
      router,
      route,
      sliderActive,
      dataEdit,
      dataSource,
      addedKlaimAgent,
      // paymentKlaim,
      closeSidebar,
      inputSearch,
      op,
      showDropDown,
      isPaymentKlaim,
      getAllData,
      showDialogHistory,
      showingHistory,
      hideDialogHistory,
      colorAndBgHistory,
      dateFilter,
      filterCal,
      sendDateFilter,
      showFilterDate,
      clearFilter,
      saveFilterDate,
      dataHistory,
      moment,
      filters,
      onPage,
      checkPermission,
      PermissionEnum,
      expandedRows,
      onRowExpand,
      onRowCollapse,
      rowExpandAct,
      formType,
      showSidebar,
      rupiahFormat,
      rowApproveAct,
      selectedRow,
      onSearch
    }
  },
})
